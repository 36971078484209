import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import coin from '../../img/coin.svg';
import bankingBg from '../../img/banking-bg.png';
import loader from '../../img/withdraw/loader.gif';
import { color, breakpoints, formStyles, button } from '../../utils/style';
import PlayerAccount from '../../components/shared/Banking/PlayerAccount';
import Deposit from '../../components/shared/Banking/Deposit';
import DepositQR from '../../components/shared/Banking/DepositQR';
import Withdraw from '../../components/shared/Banking/Withdraw';
import { bankingAuth, bankingGetDeposit, bankingGetWithdraw } from '../../utils/api/banking';
import { clearBankingToken, clearBankingUserName, setBankingToken, setBankingUserName } from '../../utils/api';
import dictionary from '../../utils/dictionary';

const Wrapper = styled('div')`
  ${formStyles}
  .successMsg {
    background-color: ${color.darkPurple};
    border-radius: 8px;
    padding: 14px;
    text-align: center;
    position: fixed;
    left: calc(50% - 160px);
    top: 20%;
    width: 320px;
    opacity: 1;
    z-index: 1;
    display: none;
    .successMsgText p {
      font-size: 18px;
    }
    a {
      background-color: ${color.darkGreen};
      width: auto;
      padding: 5px 12px;
      text-align: center;
      display: block;
    }
    &.visible {
      display: block;
      & ~ .col-3,
      & ~ .button-wrapper {
        display: none;
      }
    }
  }
  .successMsgText {
    p {
      margin-top: 30px;
      font-size: 2rem;
    }
    .small {
      font-size: 16px;
      margin-top: 20px;
    }
  }
  .errorMsg {
    background-color: ${color.red};
    padding: 5px;
    border-radius: 6px;
    margin-bottom: 10px;
    display: none;
    .visible {
      display: block;
    }
  }
  .loading {
    position: relative;
    text-align: center;
    padding-top: 100px;
    img {
      width: 100px;
    }
  }
  .loading::before {
  }
  .disable-block {
    pointer-events: none;
    opacity: 0.4;
  }
  .breadcrumbs {
  }
  .error {
    right: 10% !important;
  }
`;

const Content = styled('div')`
  position: static;
  padding: 0px 0px 30px 0px;
  display: flex;
  border-radius: 8px;
  a {
    ${button};
    margin: 39px 0;
    width: 100%;
  }
  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
  }
  @media (max-width: ${breakpoints.sm}) {
    margin-top: -15px;
  }
`;

const SideWallets = styled('div')`
  background-color: ${color.bunting};
  flex-basis: 50%;
  padding: 40px;
  border-radius: 0 8px 8px 0;
  @media (max-width: ${breakpoints.xs}) {
    padding: 20px 15px;
    border-radius: 8px;
  }
  h2 {
    margin: 40px 0 16px;
    font-size: 26px;
    color: ${color.lightningYellow};
    @media (max-width: ${breakpoints.xs}) {
      margin: 0 0 16px;
    }
  }
  .title-tabs {
    height: 45px;
    line-height: 45px;
    list-style: none;
    display: flex;
    margin-bottom: 25px;
    border-bottom: 2px solid ${color.lineGrey};
    color: ${color.lineGrey};
    font-size: 19px;
    li {
      width: max-content;
      cursor: pointer;
      transition-duration: 0.4s;
      span {
        padding-right: 30px;
      }
      &:before {
        content: '';
        position: relative;
        top: 43px;
        display: block;
        height: 2px;
        width: 0;
        background: ${color.lightningYellow};
        transition-duration: 0.4s;
      }
      &:last-child {
        span {
          padding-right: 0;
        }
      }
      &:hover,
      &.active {
        &:before {
          width: 100%;
        }
        font-size: 26px;
        color: ${color.lightningYellow};
      }
    }
  }
  
  #userDataErrorMsg {
    display: none;
    
    &.visible {
      display: block;
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    & .title-tabs {
      & li {
        & span {
          font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: ${color.greyLabel};
          padding-right: 10px;
        }
        
        &.active {
          & span {
            font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
            font-size: 23px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: ${color.lightBlue};
          }
        }
      }
    }
  }
`;

const SideBg = styled('div')`
  background: url(${bankingBg}), #0d182c;
  background-repeat: no-repeat;
  background-size: 85%;
  background-position: 34% 70%;
  min-height: 850px;
  padding: 80px 35px;
  flex-basis: 50%;
  border-radius: 8px 0 0 8px;
  opacity: 0.8;
  h1 {
    display: flex;
    position: static;
    white-space: no-wrap;
    //text-shadow: 0 6px 8px #003b6b, 0 0 14px rgba(0, 192, 255, 0.49), 0 -1px 1px rgba(0, 79, 123, 0.5),
    //  0 -1px 4px #00a8db;
    //-webkit-text-stroke: 1.4px #00c5ff;
    font-size: 50.4px;
    font-weight: bold;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${color.lightningYellow};
    img {
      position: static;
    }
    span {
      white-space: normal;
      width: 100%;
      display: block;
    }
  }
  .breadcrumbs {
    padding: 10px 20px;
  }
  .gatsby-image-wrapper {
    border-radius: 24px 24px 0 0;
    img {
      width: 100%;
    }
  }

  @media (max-width: ${breakpoints.tl}) {
    .iconImage {
      width: 30px;
    }
    h1 {
      font-size: 28px;
      left: 80px;
      top: 64px;
      img {
        left: -46px;
        top: 2px;
      }
    }
    .gatsby-image-wrapper {
      margin-bottom: 60px;
      border-radius: 8px;
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    height: 200px;
    min-height: 190px;
    margin-bottom: 20px;
    padding: 10px;
    flex-basis: 100%;
    background-position: top center;
    background-size: 85%;
    .breadcrumbs {
      position: absolute;
      margin: 10px 0;
      top: 10px;
      left: 40px;
    }

    .gatsby-image-wrapper {
      height: 140px;
      margin: 0 15px 82px;
    }
  }
`;

const Banking = ({ data, pageContext }) => {
  useEffect(
    () => () => {
      clearBankingToken();
      clearBankingUserName();
    },
    [],
  );
  const QR_DEPOSIT_MAX = 40;
  const currentPage = data.wordpressPage;
  const metaTitle =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title
      ? currentPage.yoast_meta.yoast_wpseo_title
      : `${currentPage.title}- ${process.env.SITE_NAME_SHORT}`;
  const metaDesc =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc
      ? currentPage.yoast_meta.yoast_wpseo_metadesc
      : '';
  const relatedIds = pageContext.related;
  const { breadcrumbs } = pageContext;

  // SET STATE
  const [depositData, setDepositData] = useState({});
  const [withdrawData, setWithdrawData] = useState({});
  const [operation, setOperation] = useState('');
  const [depositCount, setDepositCount] = useState(0);
  const [activeWallet, setActiveWallet] = useState(false);
  const [isLoading, setLoading] = useState(false);

  // SHOW ERROR MESSAGE
  const showErrorMsg = (msg) => {
    const errorMsg = document.getElementById('userDataErrorMsg');
    if (msg) errorMsg.innerText = msg;
    errorMsg.classList.add('visible');
    setTimeout(() => {
      errorMsg.classList.remove('visible');
    }, 5000);
  };

  // LOADER COMPONENT
  const Loader = () => (
    <div className="loading">
      <img src={loader} alt="loading" />
    </div>
  );

  // TOGGLE BETWEEN TABS ( DEPOSIT <-> WITHDRAW )
  const toggleOperations = (e) => {
    setOperation(e.target.id);
  };

  // GET TOKEN FOR BANK ACCOUNT
  const accountSubmit = (values) => {
    if (activeWallet) setActiveWallet(false);
    setLoading(true);

    // BANKING AUTH REQUEST
    bankingAuth(values.userName, values.accountNo)
      .then((json) => {
        if (!json.token) {
          showErrorMsg('ขออภัยชื่อผู้ใช้หรือบัญชีธนาคารนี้ไม่ถูกต้องหรือไม่มีอยู่ กรุณาใส่รายละเอียดที่ถูกต้อง');
          setLoading(false);
          return false;
        }

        // STORE BANKING TOKEN AND USER_NAME
        setBankingToken(json.token);
        setBankingUserName(json.username);
        setActiveWallet(true);
        setOperation('deposit');

        // GET DEPOSIT REQUEST
        return bankingGetDeposit();
      })
      .then((depositJson) => {
        setDepositData(depositJson);
        // Determine deposit form type
        setDepositCount(depositJson.depositCount);
        setLoading(false);

        // GET WITHDRAW REQUEST
        return bankingGetWithdraw();
      })
      .then((withdrawJson) => {
        setWithdrawData(withdrawJson);
      })
      .catch((err) => {
        console.error('Banking Auth request: ', err);
        setLoading(false);
      });
  };
  return (
    <Layout>
      <SEO title={metaTitle} description={metaDesc} pathname={currentPage.path} breadcrumbs={breadcrumbs} />
      <Wrapper>
        <Breadcrumbs className="breadcrumbs" breadcrumbs={breadcrumbs} page_title={currentPage.title} />

        {relatedIds.length > 0 && (
          <Content>
            <SideBg>
              <h1>
                <img className="iconImage" src={coin} alt="coin" />
                <span>{currentPage.title}</span>
              </h1>
            </SideBg>
            <SideWallets>
              <h3 id="userDataErrorMsg" />
              <h2>รายละเอียดบัญชีผู้เล่น</h2>
              <PlayerAccount activeWallet={activeWallet} accountSubmit={accountSubmit} />
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <ul
                className={activeWallet ? 'title-tabs' : 'title-tabs disable-block'}
                onClick={toggleOperations}
              >
                <li className={operation === 'deposit' ? 'active' : ''}>
                  <span id="deposit">{dictionary.depositOrTopUp}</span>
                </li>
                <li className={operation === 'withdraw' ? 'active' : ''}>
                  <span id="withdraw">{dictionary.withdraw}</span>
                </li>
              </ul>
              {isLoading && <Loader />}
              {!isLoading && activeWallet && operation === 'deposit' && depositCount <= QR_DEPOSIT_MAX &&
              <DepositQR data={depositData} operation="ฝาก-ถอน" />}
              {!isLoading && activeWallet && operation === 'deposit' && depositCount > QR_DEPOSIT_MAX &&
              <Deposit data={depositData} operation="ฝาก-ถอน" />}
              {!isLoading && activeWallet && operation !== 'deposit' &&
              <Withdraw data={withdrawData} operation="ข้อมูลการถอน" />}
            </SideWallets>
          </Content>
        )}
      </Wrapper>
    </Layout>
  );
};

export default Banking;

export const pageQuery = graphql`
    query($id: String!, $related: [Int]) {
        wordpressPage(id: { eq: $id }) {
            wordpress_id
            title
            content
            date(formatString: "MMMM DD, YYYY")
            path
            yoast_meta {
                yoast_wpseo_title
                yoast_wpseo_metadesc
            }
            acf {
                page_template
            }
            featured_media {
                alt_text
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 1980) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }

        allWordpressPost(filter: { wordpress_id: { in: $related } }, limit: 4) {
            edges {
                node {
                    slug
                    title
                    excerpt
                    featured_media {
                        alt_text
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 430) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
        allWordpressWpApiMenusMenusItems(filter: { slug: { eq: "banking" } }) {
            nodes {
                id
                name
                slug
                items {
                    type_label
                    title
                    object_slug
                    url
                    path
                }
            }
        }
    }
`;
